import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { Container } from '@material-ui/core'
import SEO from 'components/Seo'
import ExhibitionList from 'components/widgets/Exhibition/ExhibitionList'
import ExhibitionFilter from 'components/widgets/Exhibition/ExhibitionFilter'
import { useAnalysis } from 'store/Analysis'
import { TYPE_OF_LIST } from 'config/constants/panel'
import { TRACKING } from 'config/constants/common'
import { useAuth } from 'store/Auth'
import { pageView } from 'utils/tracker'
import { fetchApiAnalysis } from 'utils/apis'
import { isPresenter as checkPresenter } from 'utils/auth'
import './index.scss'

export default function TourExhibitList(props) {
  const { t } = useTranslation('exhibitions')
  const { getListPanelsViews } = useAnalysis()
  const { authData = {} } = useAuth()
  const isPresenter = checkPresenter(authData)

  useEffect(() => {
    if (isPresenter) {
      pageView(trackingEventSource)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresenter])

  const { pageContext = {}, data = {} } = props || {}
  const { listTracks = [], tourCode: currentTourCode } = pageContext || {}
  const { postgres = {} } = data || {}
  const { ecmsEventTourByTourCode: currentTour = {} } = postgres || {}
  const [filter, setFilter] = useState({})
  const [relevan, setRelevan] = useState('')
  const [selectedRadio, setSelectedRadio] = useState(TYPE_OF_LIST.MY_LIST)
  const [expanded, setExpanded] = useState(false)

  const { languageCode: currentTourLanguageCode = '' } = currentTour || {}

  useEffect(() => {
    // Fetch analysis apis
    async function fetchApis() {
      await fetchApiAnalysis(getListPanelsViews)
    }

    fetchApis()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getListPanelOfCurrentTour = () => {
    const { ecmsEventTourPanelsByTourId = {} } = currentTour || {}
    const { nodes: listPanelByTourId = [] } = ecmsEventTourPanelsByTourId || {}

    const newList = []
    listPanelByTourId.forEach((panel) => {
      const { ecmsEventPanelByPanelId = {} } = panel || {}
      newList.push(ecmsEventPanelByPanelId)
    })

    return newList
  }

  const trackingEventSource = TRACKING.SOURCE.TOUR.EXHIBIT_LIST

  return (
    <div id="TourExhibition-area" className={'Layout-children'}>
      <SEO title={t('lbl_title_en')} />
      <ExhibitionFilter
        filter={filter}
        setFilter={setFilter}
        relevan={relevan}
        setRelevan={setRelevan}
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
        expanded={expanded}
        setExpanded={setExpanded}
        haveRadio
      />

      <Container className="listExhibitions">
        <ExhibitionList
          filter={filter}
          isTour
          isAll={selectedRadio === TYPE_OF_LIST.ALL_EXHIBITION}
          isMyList={selectedRadio === TYPE_OF_LIST.MY_LIST}
          listTourPanels={getListPanelOfCurrentTour()}
          listTracks={listTracks}
          currentTourCode={currentTourCode}
          currentTourLanguageCode={currentTourLanguageCode}
          trackingEventSource={trackingEventSource}
        />
      </Container>
    </div>
  )
}

export const data = graphql`
  query($tourCode: String!) {
    postgres {
      ecmsEventTourByTourCode(tourCode: $tourCode) {
        tourName
        languageCode
        ecmsEventTourPanelsByTourId(orderBy: SEQUENCE_NO_ASC) {
          nodes {
            ecmsEventPanelByPanelId {
              panelName
              panelId
              panelDescription
              panelCode
              languageCode
              shortCode
              sequenceNo
              ecmsEventFilesByPanelId {
                nodes {
                  id
                  eventId
                  fileCategory
                  fileCode
                  fileDescription
                  fileId
                  fileSize
                  fileType
                  fileName
                  keyCode
                  url
                }
              }
              ecmsPanelTagsByPanelId {
                nodes {
                  ecmsTagListByTagListId {
                    tagGroupId
                    tagListId
                  }
                }
              }
              trackId
              ecmsEventTrackByTrackId {
                trackName
              }
              topicId
              ecmsEventTopicByTopicId {
                topicName
              }
            }
          }
        }
      }
    }
  }
`
// const getListAllPanelHasSameLanguageCode = () => {
// const { listAllTours = [] } = pageContext || {}
//   const newListTourPanels = []

//   listAllTours.forEach((tour) => {
//     const { tourCode = '', ecmsEventTourPanelsByTourId = {} } = tour || {}
//     const { nodes: listPanels = [] } = ecmsEventTourPanelsByTourId || {}

//     listPanels.forEach((panel) => {
//       const { ecmsEventPanelByPanelId = {} } = panel || {}
//       const {
//         languageCode = '',
//         trackId,
//         ecmsEventTrackByTrackId: { trackName = '' },
//         topicId,
//         ecmsEventTopicByTopicId: { topicName = '' },
//       } = ecmsEventPanelByPanelId || {}

//       const isSameCurrentTourLanguage =
//         !currentTourLanguageCode || currentTourLanguageCode.includes(languageCode)

//       if (isSameCurrentTourLanguage) {
//         const indexSection = newListTourPanels.findIndex(
//           (element) => trackId === element.trackId && topicId === element.topicId
//         )

//         if (indexSection > -1) {
//           const { list = [] } = newListTourPanels[indexSection] || {}
//           newListTourPanels[indexSection].list = [
//             ...list,
//             { ...ecmsEventPanelByPanelId, tourCode },
//           ]
//         } else {
//           newListTourPanels.push({
//             trackId,
//             trackName,
//             topicId,
//             topicName,
//             list: [{ ...ecmsEventPanelByPanelId, tourCode }],
//           })
//         }
//       }
//     })
//   })

//   return newListTourPanels
// }
